import { useMemo } from "react";
import { Link } from "react-router-dom";
import { HeadCell, PaginatedDataGrid } from "../../../components/data-grid";
import { apolloClientFetch } from "../../../components/data-grid/paginated-data-grid";
import { useLoadingNotifier } from "../../../components/loading-frame";
import { FindProjectsDocument } from "../../../generated";
import { useServices } from "../../../services-def";
import { PROJECT_EDITOR } from "../routes";

interface ProjectDefinitionItem {
  id: string;
  name: string;
}

const headers: HeadCell<ProjectDefinitionItem>[] = [
  {
    disablePadding: false,
    id: "name",
    label: "name",
    numeric: false,
    render: ProjectLink,
  },
];

function ProjectLink({ data }: { data: ProjectDefinitionItem }) {
  const { routes } = useServices();
  return (
    <Link
      to={routes.render(PROJECT_EDITOR, {
        projectId: data.id,
        selectedPath: "~",
      })}
    >
      {data.name}
    </Link>
  );
}

export function ProjectSearchHome() {
  const { apollo } = useServices();
  const { onError } = useLoadingNotifier();
  const fetch = useMemo(
    () =>
      apolloClientFetch<ProjectDefinitionItem>(
        apollo,
        FindProjectsDocument,
        onError
      ),
    [apollo, onError]
  );

  return <PaginatedDataGrid fetch={fetch} headers={headers} />;
}
